import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { CheckIn, CheckInDetail, CheckInLogDetail } from '@/api/types/model/CheckIn';
import { CompanyTree } from '@/api/types/model/Company';
import { BasicEmployee, Employee, EmployeeControl } from '@/api/types/model/Employee';
import { Law } from '@/api/types/model/Law';
import { PaginationReq } from '@/api/types/model/PaginationReq';
import { CreateProject, IProjectCheckinsReqParams, IProjectControlReqParams, Project, ProjectCoordinates, ProjectCoordinatesReq, ProjectDetail, ProjectReq } from '@/api/types/model/Project';
import { RequiredRecordType } from '@/api/types/model/Record';

export class ProjectApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Projects';
  }

  public Paginate (paginate: PaginationReq) {
    return this.doPaginate<Project>({
      path: '',
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }

  public Create (project: CreateProject) {
    return this.doReq<Project>({
      path: '',
      method: HttpVerb.POST,
      data: { ...project },
    });
  }

  public Get (projectId: number) {
    return this.doReq<ProjectDetail>({
      path: `${projectId}`,
      method: HttpVerb.GET,
    });
  }

  public Update (projectId: number, project: Project) {
    return this.doReq<Project>({
      path: `${projectId}`,
      method: HttpVerb.PUT,
      data: { ...project },
    });
  }

  public GetRequiredRecords (projectId: number) {
    return this.doReq<RequiredRecordType[]>({
      path: `${projectId}/requiredRecords`,
      method: HttpVerb.GET,
    });
  }

  public GetCompanies (projectId: number) {
    return this.doReq<CompanyTree>({
      path: `${projectId}/companies`,
      method: HttpVerb.GET,
    });
  }

  public UpdateOfficers (projectId: number, officers: BasicEmployee[]) {
    return this.doReq<Employee[]>({
      path: `${projectId}/officers`,
      method: HttpVerb.PUT,
      data: officers,
    });
  }

  public UpdateControllers (projectId: number, officers: BasicEmployee[]) {
    return this.doReq<Employee[]>({
      path: `${projectId}/controllers`,
      method: HttpVerb.PUT,
      data: officers,
    });
  }

  public UpdateLaws (projectId: number, lawIds: number[]) {
    return this.doReq<Law[]>({
      path: `${projectId}/laws`,
      method: HttpVerb.PUT,
      data: lawIds,
    });
  }

  public GetQR (projectId: number) {
    return this.doReq<string>({
      path: `${projectId}/QR`,
      method: HttpVerb.GET,
    });
  }

  public GetControl (projectId: number, params: IProjectControlReqParams) {
    return this.doPaginate<EmployeeControl>({
      path: `${projectId}/control`,
      method: HttpVerb.POST,
      data: { ...params },
    });
  }

  public GetControlDetails (projectId: number, employeeId: number) {
    return this.doReq<CheckInLogDetail>({
      path: `${projectId}/control/${employeeId}`,
      method: HttpVerb.GET,
    });
  }

  public GetEmployeeCheckIn (projectId: number, employeeId: number) {
    return this.doReq<CheckInDetail>({
      path: `${projectId}/checkIn/${employeeId}`,
      method: HttpVerb.GET,
    });
  }

  public GetCheckIns (projectId: number, params: IProjectCheckinsReqParams) {
    return this.doPaginate<CheckIn>({
      path: `${projectId}/checkins`,
      method: HttpVerb.POST,
      data: { ...params },
    });
  }

  public InviteCompanyChain (project: ProjectReq) {
    return this.doReq<Project>({
      path: 'invite-company-chain',
      method: HttpVerb.POST,
      data: project,
    });
  }

  public AddCoordinates (coordinates: ProjectCoordinatesReq) {
    return this.doReq<ProjectCoordinates>({
      path: 'location',
      method: HttpVerb.POST,
      data: coordinates,
    });
  }

  public UpdateCoordinates (coordinates: ProjectCoordinatesReq) {
    return this.doReq<ProjectCoordinates>({
      path: `location/${coordinates.projectId}`,
      method: HttpVerb.PUT,
      data: coordinates,
    });
  }
}
