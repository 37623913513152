import { subdomainLoginType } from '@/utils/environment';
import { useCookies as useVue3Cookies } from 'vue3-cookies';

const Cookie = {
  AccessToken: 'accessToken',
  RefreshToken: 'refreshToken',
  ExpiresAt: 'expiresAt',
  CheckinAccessToken: 'checkinAccessToken',
  CheckinRefreshToken: 'checkinRefreshToken',
  CheckinExpiresAt: 'checkinExpiresAt',
};

interface TokenKeys {
  accessToken: string,
  refreshToken: string,
  expiresAt: string,
}

export function useCookies () {
  const { cookies } = useVue3Cookies();

  const getTokenKeys = (): TokenKeys => {
    const loginType = subdomainLoginType();
    return {
      accessToken: loginType === 'checkin' ? Cookie.CheckinAccessToken : Cookie.AccessToken,
      refreshToken: loginType === 'checkin' ? Cookie.CheckinRefreshToken : Cookie.RefreshToken,
      expiresAt: loginType === 'checkin' ? Cookie.CheckinExpiresAt : Cookie.ExpiresAt,
    };
  };

  const clearTokens = () => {
    const keys = getTokenKeys();
    cookies.remove(keys.accessToken);
    cookies.remove(keys.refreshToken);
    cookies.remove(keys.expiresAt);
  };

  const setTokens = (accessToken?: string, refreshToken?: string, expiresAt?: string) => {
    if (!accessToken || !refreshToken || !expiresAt) {
      return clearTokens();
    }
    const date = new Date;
    date.setDate(date.getDate() + 30);
    const keys = getTokenKeys();
    cookies.set(keys.accessToken, accessToken, date);
    cookies.set(keys.refreshToken, refreshToken, date);
    cookies.set(keys.expiresAt, expiresAt, date);
  };

  const getTokens = (): { accessToken?: string, refreshToken?: string, expiresAt?: Date } => {
    const keys = getTokenKeys();
    const expiresAt = cookies.get(keys.expiresAt);
    return {
      accessToken: cookies.get(keys.accessToken),
      refreshToken: cookies.get(keys.refreshToken),
      expiresAt: expiresAt ? new Date(expiresAt) : undefined,
    };
  };

  return {
    setTokens,
    getTokens,
    clearTokens,
  };
}