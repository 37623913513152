import { useLanguageStore } from '@/stores/language';
import { PlaceholderParam } from '@/utils/string-placeholder';

export type TextPropDetail = {
  k: string;
  params?: PlaceholderParam;
  defaultText?: string;
};

export type TextProp = string | number | TextPropDetail;

export function useTranslation () {
  const languageStore = useLanguageStore();
  return { $t: languageStore.text };
}

export default {
  install: (app) => {
    const languageStore = useLanguageStore();
    app.config.globalProperties.$t = (info?: TextProp): string => {
      return languageStore.text(info);
    };
    app.config.globalProperties.$tDyn = (k: string, params?: PlaceholderParam, defaultText?: string): string => {
      return languageStore.textDyn(k, params, defaultText);
    };
  },
};
