import { ApiBase, RawResponse } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { CheckInAudit } from '@/api/types/model/CheckInAudit';
import { CheckIn } from '@/api/types/model/CheckIn';

export class CheckInApi extends ApiBase {
  setRouteGroup (): string {
    return 'CheckIn';
  }

  public CheckIn (projectId: number) {
    return this.doRawReq<RawResponse<CheckIn>>({
      path: `${projectId}`,
      method: HttpVerb.GET,
    });
  }

  public CheckInColleague (projectId: number, phoneNumber: string) {
    return this.doRawReq<RawResponse<CheckIn>>({
      path: `${projectId}/${phoneNumber}`,
      method: HttpVerb.GET,
    });
  }

  public Audit (projectId: number, logId: number, approved: boolean, comment: string) {
    return this.doReq<CheckInAudit>({
      path: `${projectId}/audit/${logId}`,
      method: HttpVerb.PUT,
      data: {
        approved,
        comment,
      },
    });
  }
}