import { ApiBase } from '@/api/apis/ApiBase';
import { ContentType, Header, HttpVerb } from '@/api/types/enum/Http';
import { FileReq } from '@/api/types/model/FileReq';
import { IdDocument } from '@/api/types/model/IdDocument';

export class IdentityDocumentApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'IdentityDocuments';
  }

  public Upload (frontOfDocument: FileReq, backOfDocument?: FileReq) {
    const formData = new FormData();
    formData.append('frontOfDocument', frontOfDocument.data as Blob, frontOfDocument.fileName);
    if (backOfDocument) {
      formData.append('backOfDocument', backOfDocument.data as Blob, backOfDocument.fileName);
    }
    return this.doReq<IdDocument>({
      path: 'upload',
      method: HttpVerb.POST,
      data: formData,
      headers: {
        [Header.contentType]: ContentType.FORMDATA,
      },
    });
  }
}
