import { Primitive } from '@/utils/common-types';

type PlaceholderParamArray = Primitive[];
export type PlaceholderParamObject = Record<string, Primitive>;
export type PlaceholderParam = PlaceholderParamArray | PlaceholderParamObject;

const convertArrayToObject = (array: PlaceholderParamArray): PlaceholderParamObject => {
  const obj: PlaceholderParamObject = {};
  if (array.length === 0) {
    return obj;
  }
  array.forEach((value: Primitive | undefined, index: number) => {
    obj[index.toString()] = value?.toString() || '';
  });
  return obj;
};

export const applyPlaceholders = (str?: string, params?: PlaceholderParam): string => {
  const inStr = str || '';
  if (!params) return inStr;
  const tParams = Array.isArray(params) ? convertArrayToObject(params) : params;
  return inStr.replace(/{([a-zA-Z0-9_]+)}/g, (match: string, key: string): string => {
    return !!tParams[key] ? tParams[key].toString() : match;
  });
};
