import { DefaultLanguageCode } from '@/stores/language';

enum Key {
  LanguagePreference = 'langPreference',
  KeepProjectId = 'keepProjectId',
  UserCompanyProject = 'userCompanyProject'
};

const setToLocalStorage = (key: string, value: string) => {
  if (window.localStorage) window.localStorage.setItem(key, value);
};

const getFromLocalStorage = (key: string): string | undefined => {
  return window.localStorage?.getItem(key) || undefined;
};

const removeFromLocalStorage = (key: string) => {
  if (window.localStorage) window.localStorage.removeItem(key);
};

export function useLocalStorage () {
  const setLanguagePreference = (languageCode: string) => {
    setToLocalStorage(Key.LanguagePreference, languageCode);
  };

  const getLanguagePreference = (): string => {
    return getFromLocalStorage(Key.LanguagePreference) || DefaultLanguageCode;
  };

  const setKeepProjectId = (projectId: number) => {
    setToLocalStorage(Key.KeepProjectId, projectId.toString());
  };

  const getKeepProjectId = (): number | undefined => {
    const projectId = getFromLocalStorage(Key.KeepProjectId);
    if (projectId) {
      removeFromLocalStorage(Key.KeepProjectId);
    }
    return projectId ? parseInt(projectId) : undefined;
  };

  const setUserCompanyProject = (userId: number, companyId: number, projectId: number) => {
    const str = `${userId}-${companyId}-${projectId}`;
    setToLocalStorage(Key.UserCompanyProject, str);
  };

  // return [userId: number, companyId: number, projectId: number]
  const getUserCompanyProject = (): number[] | undefined => {
    const userCompProj = getFromLocalStorage(Key.UserCompanyProject);
    if (!userCompProj) return;

    return userCompProj.split('-').map((id) => +id);
  };

  return {
    setLanguagePreference,
    getLanguagePreference,
    setKeepProjectId,
    getKeepProjectId,
    setUserCompanyProject,
    getUserCompanyProject,
  };
};
