import { ClientConfiguration } from '@/api/client/Client';
import { RequestConfig } from '@/api/types/base/RequestConfig';


export enum ApiLibraryType {
  AXIOS = 'axios',
};

export abstract class ApiLibraryBase {
  protected config: ClientConfiguration;

  constructor (config: ClientConfiguration) {
    this.config = config;
  }

  abstract makeRequest(request: RequestConfig);
}