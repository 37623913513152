import { ClientConfiguration } from '@/api/client/Client';
import { RequestConfig } from '@/api/types/base/RequestConfig';
import { ApiLibraryBase } from '@/api/library/ApiLibraryBase';
import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from 'axios';
import { useCookies } from '@/composables/storage/useCookies';


export class AxiosLibrary extends ApiLibraryBase {
  private readonly instance: AxiosInstance;

  constructor (config: ClientConfiguration) {
    super(config);
    this.instance = axios.create({
      baseURL: config.url,
    });
    this.setupInterceptors();
  }

  public makeRequest (request: RequestConfig) {
    const requestConfig: AxiosRequestConfig = {
      url: request.path,
      method: request.method,
      data: request.data,
      headers: request.headers,
      responseType: request.responseType,
    };
    return this.instance.request(requestConfig);
  }


  private setupInterceptors () {
    this.instance.interceptors.request.use(this.onRequest as unknown as any, this.onRequestError);
    this.instance.interceptors.response.use(this.onResponse, this.onResponseError);
  }

  private onRequest (config: AxiosRequestConfig): AxiosRequestConfig {
    const { getTokens } = useCookies();
    const { accessToken } = getTokens();
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return config;
  }

  private onRequestError (error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  }

  private onResponse (response) {
    return response.data;
  }

  private onResponseError (error: AxiosError): Promise<AxiosError> {
    return Promise.reject(error);
  };
}