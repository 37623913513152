import { CompliApi } from '@/api/CompliApi';
import { TranslationGroup } from '@/api/types/model/Translation';
import offlineTranslations from '@/assets/offline-translations.json';
import { defineStore } from 'pinia';
import { TextProp, TextPropDetail } from '@/plugins/translations';
import { applyPlaceholders, PlaceholderParam } from '@/utils/string-placeholder';
import { useLocalStorage } from '@/composables/storage/useLocalStorage';
import { useCookies } from '@/composables/storage/useCookies';

const offlineText = (key: string, languageCode: string, params?: PlaceholderParam, defaultText?: string): string => {
  const keySplit = key?.split('.') || [];
  const textDef = offlineTranslations?.[keySplit?.[0]]?.[keySplit?.[1]];
  const text = textDef?.[languageCode] || textDef?.en;
  if (typeof text === 'string') return applyPlaceholders(text, params);
  if (defaultText) return applyPlaceholders(defaultText, params);
  return applyPlaceholders(key, params);
};

export const DefaultLanguageCode = 'nl';

interface State {
  currentLanguageCode: string;
  languages: string[];
  translationsLoading: boolean;
  translations: Record<string, TranslationGroup>;
};

export const useLanguageStore = defineStore('language', {
  state: (): State => ({
    currentLanguageCode: DefaultLanguageCode,
    languages: [],
    translationsLoading: false,
    translations: {},
  }),
  actions: {
    setCurrentLanguage (languageCode: string) {
      const { setLanguagePreference } = useLocalStorage();
      this.currentLanguageCode = languageCode;
      setLanguagePreference(languageCode);
      return this.fetchTranslations();
    },
    async init () {
      const { getLanguagePreference } = useLocalStorage();
      const { getTokens } = useCookies();
      this.currentLanguageCode = getLanguagePreference();
      const { accessToken } = getTokens();
      if (accessToken) {
        await this.fetchLanguages();
        return this.fetchTranslations();
      }
    },
    async fetchLanguages () {
      if (this.languages.length > 0) return;
      try {
        const result = await CompliApi.Language.Available();
        const languages = Object.keys(result);
        this.languages = languages.length ? languages : [ DefaultLanguageCode ];
        if (!this.languages.includes(this.currentLanguageCode)) {
          this.setCurrentLanguage(languages[0]);
        }
      } catch (e) {

      }
    },
    async fetchTranslations () {
      if (this.translations?.[this.currentLanguageCode]) return;
      try {
        this.translationsLoading = true;
        const result = await CompliApi.Language.Translations(this.currentLanguageCode);
        this.translations[this.currentLanguageCode] = result.translations;
      } catch (e) {

      } finally {
        this.translationsLoading = false;
      }
    },
  },
  getters: {
    text: (state) => {
      return (info?: TextProp): string => {
        if (typeof info === 'undefined' || state.translationsLoading) return '';
        const outInfo: TextPropDetail = typeof info === 'string' || typeof info === 'number' ? { k: info.toString() } : info;
        const keySplit = outInfo.k?.split('.') || [];
        const text = keySplit.length !== 2 ? outInfo.k : state.translations?.[state.currentLanguageCode]?.[keySplit[0]]?.[keySplit[1]];
        return typeof text === 'string' ? applyPlaceholders(text, outInfo.params) : offlineText(outInfo.k, state.currentLanguageCode, outInfo.params, outInfo.defaultText);
      };
    },
    textDyn () {
      return (k: string, params?: PlaceholderParam, defaultText?: string): string => {
        const outInfo: TextProp = {
          k,
          params,
          defaultText,
        };
        return this.text(outInfo);
      };
    },
  },
});