import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { CheckInLogSummaryParams } from '@/api/types/model/CheckInLog';
import { PaginationReq } from '@/api/types/model/PaginationReq';
import { CheckinProject } from '@/api/types/model/Project';

export class CheckInLogApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'CheckInLogs';
  }

  public GetSummary (_pagination: PaginationReq, params: CheckInLogSummaryParams) {
    return this.doPaginate<CheckinProject>({
      path: 'summary',
      method: HttpVerb.POST,
      data: { ...params },
    });
  }
}
