import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { RecordType } from '@/api/types/model/RecordType';

export class RecordTypeApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'RecordTypes';
  }
  public GetAll () {
    return this.doReq<RecordType[]>({
      path: '',
      method: HttpVerb.GET,
    });
  }
}