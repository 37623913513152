import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { CofCSearch } from '@/api/types/model/KVK';

export class KVKApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'KVK';
  }

  public Query (query: string) {
    return this.doReq<CofCSearch[]>({
      path: `${query}`,
      method: HttpVerb.GET,
    });
  };
}