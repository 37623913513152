import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { Answer, AnswerDTO } from '@/api/types/model/Answer';
import { PaginationReq } from '@/api/types/model/PaginationReq';

export class AnswerApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Answers';
  }

  public Paginate (paginate?: PaginationReq) {
    return this.doPaginate<Answer>({
      path: '',
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }

  public Get (answerId: number) {
    return this.doReq<Answer>({
      path: `${answerId}`,
      method: HttpVerb.GET,
    });
  }

  public Create (answer: AnswerDTO) {
    return this.doReq<Answer>({
      path: '',
      method: HttpVerb.POST,
      data: answer,
    });
  }

  public Update (answerId: number, answer: AnswerDTO) {
    return this.doReq<Answer>({
      path: `${answerId}`,
      method: HttpVerb.PUT,
      data: answer,
    });
  }
}