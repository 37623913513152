import { RequestConfig } from '@/api/types/base/RequestConfig';
import { ContentType, Header } from '@/api/types/enum/Http';
import { ApiLibraryBase, ApiLibraryType } from '@/api/library/ApiLibraryBase';
import { AxiosLibrary } from '@/api/library/AxiosLibrary';
import { trimPatternLeft, trimPatternRight, trimSlashes } from '@/utils/string';
import { createQueryString } from '@/utils/url';

export type ClientConfiguration = {
  url: string,
  library?: ApiLibraryType,
  apiPrefix?: string;
};

export class Client {
  private readonly library: ApiLibraryBase;
  private readonly config: ClientConfiguration;

  constructor (config: ClientConfiguration) {
    this.config = config;
    this.library = this.getLibrary(config);
  }

  public makeRequest (request: RequestConfig) {
    const requestConfig: RequestConfig = {
      ...request,
      path: this.getPath(request),
      headers: {
        ...this.getDefaultHeaders(),
        ...(request.headers || {}),
      },
    };
    return this.library.makeRequest(requestConfig);
  }

  private getDefaultHeaders (): Record<string, string> {
    return {
      [Header.accept]: ContentType.JSON,
      [Header.contentType]: ContentType.JSON,
    };
  }

  private getLibrary (config: ClientConfiguration): ApiLibraryBase {
    switch (config.library) {
    case ApiLibraryType.AXIOS:
    default:
      return new AxiosLibrary(config);
    }
  }

  private getApiPrefix (version?: number): string {
    const versionString = !!version ? `v${version}` : '';
    return trimSlashes(`${this.config.apiPrefix || ''}/${versionString}`);
  }

  private getPath (request: RequestConfig): string {
    const apiPrefix: string = this.getApiPrefix(request.version);
    let path: string = trimSlashes(request.path);
    path = trimPatternLeft(path, `${apiPrefix}/`);
    path = trimPatternRight(path, '[?]');
    const routeGroup: string = !!request.pathGroup ? `${request.pathGroup}/` : '';
    path = `${apiPrefix}/${routeGroup}${path}`;
    const query: string | undefined = createQueryString(request.query);
    if (query) {
      // check if already added something in query and append
      const querySplitted: string[] = query.split('?');
      const separator: string = querySplitted.length === 2 ? '&' : '?';
      path = `${path}${separator}${query}`;
    }
    return trimPatternRight(path, '/');
  }
};