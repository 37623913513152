interface JWTData {
  companyIds: number[];
  selectedCompanyId: number;
  controllingProjectId: number[];
  companyProjectIds: number[];
  employeeIds: number[];
  customerIds: number[];
  aud: string;
  exp: Date;
  roles: string[],
  name: string,
  iss: string;
  jti: string;
  nbf: number;
}

export function parseJWT (token: string): JWTData {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    const payload = JSON.parse(jsonPayload);
    return {
      companyIds: getCompanyIds(payload),
      selectedCompanyId: parseInt(payload.SelectedCompanyId || 0),
      controllingProjectId: getControllingProjectIds(payload),
      companyProjectIds: getCompanyProjectIds(payload),
      employeeIds: getEmployeeIds(payload),
      customerIds: getCustomerIds(payload),
      aud: payload.aud || '',
      exp: payload.exp ? new Date(payload.exp * 1000) : new Date(),
      roles: getRoles(payload),
      name: getGivenName(payload) || getName(payload),
      iss: payload.iss || '',
      jti: payload.jti || '',
      nbf: payload.nbf || 0,
    };
  } catch {
    return {} as JWTData;
  }
}

function getCompanyIds (payload): number[] {
  const raw = payload['CompanyId'] || [];
  const companyIds = typeof raw === 'string' ? [raw] : raw;
  return companyIds.map(id => parseInt(id));
}

function getCompanyProjectIds (payload): number[] {
  const companyProjectIds = payload['CompanyProjectId'];
  if (!companyProjectIds) return [];
  const tmp = (typeof companyProjectIds === 'string') ? [companyProjectIds] : companyProjectIds;
  const companyIds: number[] = tmp.map(entry => {
    const spl = entry.split(',');
    return spl[0];
  }).filter(entry => !!entry).map(entry => parseInt(entry));
  return [...new Set(companyIds)];
}

function getEmployeeIds (payload): number[] {
  const raw = payload['EmployeeId'] || [];
  const employeeIds = typeof raw === 'string' ? [raw] : raw;
  return employeeIds.map(id => parseInt(id));
}

function getCustomerIds (payload): number[] {
  const raw = payload['CustomerId'] || [];
  const employeeIds = typeof raw === 'string' ? [raw] : raw;
  return employeeIds.map(id => parseInt(id));
}

function getRoles (payload): string[] {
  const roles = payload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  if (!roles) return [];
  if (typeof roles === 'string') return [roles];
  return roles;
}

function getName (payload): string {
  return payload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] || '';
}

function getGivenName (payload): string {
  return payload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'] || '';
}

function getControllingProjectIds (payload): number[] {
  const companyIds = payload['ControllingProjectId'];
  if (!companyIds) return [];
  if (typeof companyIds === 'string') return [parseInt(companyIds)];
  return companyIds.map(id => parseInt(id));
}