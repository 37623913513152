import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { Perspective } from '@/api/types/enum/Perspective';
import { QuestionnaireTree } from '@/api/types/model/QuestionnaireTree';
import { PaginationReq } from '@/api/types/model/PaginationReq';

export class QuestionnaireApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Questionnaires';
  }

  public Paginate (paginate?: PaginationReq) {
    return this.doPaginate<QuestionnaireTree>({
      path: '',
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }

  public GetAll () {
    return this.doReq<QuestionnaireTree[]>({
      path: 'getAll',
      method: HttpVerb.GET,
    });
  }

  public Get (lawId: number, perspective: Perspective, language: string) {
    return this.doReq<QuestionnaireTree>({
      path: `${lawId}/${perspective}/${language}`,
      method: HttpVerb.GET,
    });
  }
  public GetQuestionnaire (lawId: number, perspective: Perspective) {
    return this.doReq<QuestionnaireTree>({
      path: `${lawId}/${perspective}`,
      method: HttpVerb.GET,
    });
  }

  public Create (questionnaire: QuestionnaireTree) {
    return this.doReq<QuestionnaireTree>({
      path: '',
      method: HttpVerb.POST,
      data: questionnaire,
    });
  }

  public Update (lawId: number, perspective: Perspective, questionnaire: QuestionnaireTree) {
    return this.doReq<QuestionnaireTree>({
      path: `${lawId}/${perspective}`,
      method: HttpVerb.PUT,
      data: questionnaire,
    });
  }
}