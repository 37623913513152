export const trimSlashes = (str: string): string => {
  return str.replace(/^(\/)+|(\/)+$/g, '');
};

export const trimPattern = (str: string, pattern: string): string => {
  const re = new RegExp(`^${pattern}|${pattern}$`, 'g');
  return str.replace(re, '');
};

export const trimPatternLeft = (str: string, pattern: string): string => {
  const re = new RegExp(`^${pattern}`);
  return str.replace(re, '');
};

export const trimPatternRight = (str: string, pattern: string): string => {
  const re = new RegExp(`${pattern}$`);
  return str.replace(re, '');
};

export const capitalize = (input: string): string => {
  if (!input) return '';
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const sanitizeUserName = (input?: string): string => {
  if (!input) return '';
  let out = input;
  out = out.replace(/[0-9]/gi, ''); // remove numbers
  out = out.split(/\.|_/).join(' ').trim(); // split by dot or underscore
  out = out.split(/\s+/).map(word => capitalize(word).trim()).join(' ').trim(); // split by space and capitalize each word
  return out;
};