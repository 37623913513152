import { ApiBase } from '@/api/apis/ApiBase';
import { ContentType, Header, HttpVerb } from '@/api/types/enum/Http';
import { FileReq } from '@/api/types/model/FileReq';
import { Language } from '@/api/types/model/Language';
import { TranslationReq, AdminApiTranslations, Translations } from '@/api/types/model/Translation';

export class LanguageApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'language';
  }

  public All () {
    return this.doReq<Record<Language, string>>({
      path: '',
      method: HttpVerb.GET,
    });
  }

  public Available () {
    return this.doReq<Record<Language, string>>({
      path: 'available',
      method: HttpVerb.GET,
    });
  }

  public Translations (languageCode: string) {
    return this.doReq<Translations>({
      path: `translations/${languageCode}`,
      method: HttpVerb.GET,
    });
  }

  public AllTranslations () {
    return this.doReq<AdminApiTranslations[]>({
      path: 'translations/all',
      method: HttpVerb.GET,
    });
  };

  public InsertTranslation (translation: TranslationReq) {
    return this.doEmptyReq({
      path: 'translations/insert',
      method: HttpVerb.POST,
      data: { ...translation },
    });
  }

  public UpdateTranslation (translation: TranslationReq) {
    return this.doEmptyReq({
      path: 'translations/update',
      method: HttpVerb.POST,
      data: { ...translation },
    });
  }

  public ExportTranslationsToJson () {
    return this.doRawReq<Blob>({
      path: 'translations/file-all',
      method: HttpVerb.GET,
      responseType: 'blob',
      headers: {
        [Header.accept]: ContentType.OCTETSTREAM,
        [Header.contentType]: ContentType.OCTETSTREAM,
      },
    });
  }

  public ImportTranslations (file: FileReq) {
    const formData = new FormData();
    formData.append('translationsJson', file.data as Blob, file.fileName);
    return this.doEmptyReq({
      path: 'translations/bulk-insert-file',
      method: HttpVerb.POST,
      data: formData,
      headers: {
        [Header.contentType]: ContentType.FORMDATA,
      },
    });
  }
}
