import { LoadScript } from '@/utils/load-script';
declare global {
  interface Window { DESKPRO_MESSENGER_OPTIONS: any; }
}

export default {
  install: () => {
    window.DESKPRO_MESSENGER_OPTIONS = {
      language: {
        id: '5',
        locale: 'nl',
      },
      helpdeskURL: 'https://support.compli.nl',
    };
    const loader = new LoadScript('https://support.compli.nl/dyn-assets/pub/build/messenger/loader.js?v=599443200', {
      attrs: {
        id: 'dp-messenger-loader',
      },
    });
    loader.load();
  },
};