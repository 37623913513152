import { ApiBase } from '@/api/apis/ApiBase';
import { ContentType, Header, HttpVerb } from '@/api/types/enum/Http';
import { CreateRecordReq, RecordDetail, RecordValidateReq, RequiredRecordType } from '@/api/types/model/Record';

export class RecordApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Records';
  }

  public Validate (recordId: number, recordValidateModel: RecordValidateReq) {
    return this.doReq<RecordDetail>({
      path: `${recordId}`,
      method: HttpVerb.PUT,
      data: recordValidateModel,
    });
  }

  public GetDocument (recordId: number) {
    return this.doReq<string>({
      path: `${recordId}/document`,
      method: HttpVerb.GET,
    });
  }

  public Create (req: CreateRecordReq) {
    const formData = new FormData();
    if (req.ProjectId) {
      formData.append('ProjectId', req.ProjectId.toString());
    }
    formData.append('RecordTypeId', req.RecordTypeId.toString());
    if (req.CompanyId) formData.append('CompanyId', req.CompanyId.toString());
    if (req.EmployeeId) formData.append('EmployeeId', req.EmployeeId.toString());
    if (req.StartDate) formData.append('StartDate', req.StartDate.toJSON());
    if (req.EndDate) formData.append('EndDate', req.EndDate.toJSON());
    if (req.Files?.length) {
      req.Files.forEach(file => formData.append('Files', file.data as Blob, file.fileName || 'Files'));
    }
    if (req.RecordValuesJSON) formData.append('RecordValuesJSON', req.RecordValuesJSON);
    if (req.RecordValues) formData.append('RecordValues', JSON.stringify(req.RecordValues));
    return this.doReq<RecordDetail>({
      path: '',
      method: HttpVerb.POST,
      data: formData,
      headers: {
        [Header.contentType]: ContentType.FORMDATA,
      },
    });
  }

  public All () {
    return this.doReq<RecordDetail[]>({
      path: '',
      method: HttpVerb.GET,
    });
  }

  public GetEmployeeRequiredRecordIds (employeeId: number) {
    return this.doRawReq<RequiredRecordType[]>({
      path: 'employee-required-documentIds',
      method: HttpVerb.GET,
      query: {
        employeeId,
      },
    });
  }

  public GetCompanyRequiredRecordIds (companyId: number) {
    return this.doRawReq<RequiredRecordType[]>({
      path: 'company-required-documentIds',
      method: HttpVerb.GET,
      query: {
        companyId,
      },
    });
  }

  public EncryptAllBsn () {
    return this.doRawReq({
      path: 'encrypt-all-bsn',
      method: HttpVerb.GET,
    });
  }

  public GetEmployeeDecryptedBsn (encryptedBSN: string) {
    const formData = new FormData();
    formData.append('toDecrypt', encryptedBSN);

    return this.doRawReq<string>({
      path: 'decrypt-value',
      method: HttpVerb.POST,
      data: formData,
      headers: {
        [Header.contentType]: ContentType.FORMDATA,
      },
    });
  }
}
