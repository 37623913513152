import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { CreateEmployee, Employee, EmployeeDetail, EmployeeDetailedControl, EmployeeDetailedControlCsvParams, EmployeeDetailedControlParams } from '@/api/types/model/Employee';
import { IdDocument } from '@/api/types/model/IdDocument';
import { PaginationReq } from '@/api/types/model/PaginationReq';
import { RecordDetail } from '@/api/types/model/Record';

export class EmployeeApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Employees';
  }

  public Paginate (paginate: PaginationReq) {
    return this.doPaginate<Employee>({
      path: '',
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }

  public Create (employee: Employee, inviterCompanyName: string, language: string) {
    return this.doReq<Employee>({
      path: '',
      method: HttpVerb.POST,
      data: { ...employee },
      query: {
        inviterCompanyName,
        language,
      },
    });
  }

  public CreateWithDocument (employee: CreateEmployee, idUpload: IdDocument) {
    return this.doReq<Employee>({
      path: 'create-with-document',
      method: HttpVerb.POST,
      data: { employee, idUpload },
    });
  }

  public Get (employeeId: number) {
    return this.doReq<EmployeeDetail>({
      path: `${employeeId}`,
      method: HttpVerb.GET,
    });
  }

  public Update (employeeId: number, employee: Employee) {
    return this.doReq<Employee>({
      path: `${employeeId}`,
      method: HttpVerb.PUT,
      data: employee,
    });
  }

  public Toggle (employeeId: number) {
    return this.doReq<Employee>({
      path: `${employeeId}/toggle`,
      method: HttpVerb.PUT,
    });
  }

  public GetDocuments (employeeId: number) {
    return this.doReq<RecordDetail[]>({
      path: `${employeeId}/documents`,
      method: HttpVerb.GET,
    });
  }

  public GetPendingEmployees () {
    return this.doReq<Employee[]>({
      path: 'no-answers',
      method: HttpVerb.GET,
    });
  }

  public GetDetailedControl (_paginate: PaginationReq, params: EmployeeDetailedControlParams) {
    return this.doPaginate<EmployeeDetailedControl[]>({
      path: 'detailed-control',
      method: HttpVerb.POST,
      data: { ...params },
    });
  }

  public GetDetailedControlCsv (params: EmployeeDetailedControlCsvParams) {
    return this.doRawReq<Blob>({
      path: 'detailed-control/csv',
      responseType: 'blob',
      method: HttpVerb.POST,
      data: { ...params },
    });
  }
}
