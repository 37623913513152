import { ApiBase } from '@/api/apis/ApiBase';
import { Perspective } from '@/api/types/enum/Perspective';
import { HttpVerb } from '@/api/types/enum/Http';
import { AnswerInput, AnswerInputRequestBody } from '@/api/types/model/AnswerInput';

export class AnswerInputApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'AnswerInputs';
  }

  public saveProjectAnswers (perspective: Perspective, projectId: number, requestBody: AnswerInputRequestBody) {
    return this.doReq<AnswerInput[]>({
      path: `${perspective}/${projectId}`,
      method: HttpVerb.PUT,
      data: requestBody,
    });
  };

  public saveEmployeeAnswers (perspective: Perspective, projectId: number, employeeId: number, answerInputs: AnswerInput[]) {
    return this.doReq<AnswerInput[]>({
      path: `${perspective}/${projectId}/${employeeId}`,
      method: HttpVerb.PUT,
      data: answerInputs,
    });
  };
}