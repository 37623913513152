import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { EmployeeInvite } from '@/api/types/model/EmployeeInvite';
import { KlippaToken } from '@/api/types/model/KlippaToken';
import { PaginationReq } from '@/api/types/model/PaginationReq';

export class InviteApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Invites';
  }

  public createEmployeeInvite (firstName: string, lastName: string, phoneNumber: string, update: boolean = false) {
    return this.doEmptyReq({
      path: 'create-employee-invite',
      method: HttpVerb.POST,
      query: {
        firstName,
        lastName,
        phoneNumber,
        update,
      },
    });
  }

  public checkInvite (encodedInviteString: string) {
    return this.doEmptyReq({
      path: 'check-invite',
      method: HttpVerb.GET,
      query: {
        encodedInviteString,
      },
    });
  }

  public CreateKlippaToken () {
    return this.doReq<KlippaToken>({
      path: 'klippa-identity-verification/create-token',
      method: HttpVerb.GET,
    });
  }

  public CheckKlippaStatus (token: string) {
    return this.doReq<KlippaToken>({
      path: 'klippa-identity-verification/check-status',
      method: HttpVerb.GET,
      query: {
        token,
      },
    });
  }

  public CreateKlippaUser (token: string, encodedInviteString: string) {
    return this.doEmptyReq({
      path: 'klippa-identity-verification/create-user',
      method: HttpVerb.POST,
      query: {
        token,
        encodedInviteString,
      },
    });
  }

  public GetEmployeeInvites (paginate: PaginationReq) {
    return this.doPaginate<EmployeeInvite>({
      path: 'get-employee-invites',
      method: HttpVerb.GET,
      query: {
        ...paginate,
      },
    });
  }
}