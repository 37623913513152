import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';

export class CountryApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Countries';
  }

  public All () {
    return this.doReq<Record<string, string>>({
      path: '',
      method: HttpVerb.GET,
    });
  }

  public EU () {
    return this.doReq<Record<string, string>>({
      path: 'EU',
      method: HttpVerb.GET,
    });
  }
}