
import { Client } from '@/api/client/Client';
import { RequestConfig } from '@/api/types/base/RequestConfig';

export interface RawResponse<T> {
  result?: T;
  errorMessages?: string[];
  success?: boolean;
}

interface TempResponse<T> {
  result: T;
  total?: number;
}

export interface PaginateResponse<T> {
  result: T[],
  total: number,
};

export abstract class ApiBase {
  protected client: Client;
  protected routeGroup: string;

  constructor (client: Client) {
    this.client = client;
    this.routeGroup = this.setRouteGroup();
  }

  protected abstract setRouteGroup(): string;

  private doClientRequest<T> (request: RequestConfig): Promise<TempResponse<T>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.client.makeRequest({
          ...request,
          pathGroup: request.pathGroup || this.routeGroup,
        });
        if (!response.success) {
          return reject(new Error(response.errorMessages || []));
        }
        resolve({
          result: response.result,
          total: response.total,
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  protected async doRawReq<T> (request: RequestConfig): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.client.makeRequest({
          ...request,
          pathGroup: request.pathGroup || this.routeGroup,
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  protected async doEmptyReq (request: RequestConfig): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        await this.client.makeRequest({
          ...request,
          pathGroup: request.pathGroup || this.routeGroup,
        });
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  protected async doReq<T> (request: RequestConfig): Promise<T> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.doClientRequest<T>(request);
        resolve(response.result);
      } catch (e) {
        reject(e);
      }
    });
  }

  protected async doPaginate<T> (request: RequestConfig): Promise<PaginateResponse<T>> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.doClientRequest<T>(request);
        if (!Array.isArray(response.result)) {
          return reject(new Error('Response is invalid paginate request'));
        }
        resolve({
          result: response.result,
          total: response.total || response.result.length,
        });
      } catch (e) {
        reject(e);
      }
    });
  };
}