import { LoginType } from '@/api/types/model/Auth';

export const Environment = {
  LOCALHOST: 'localhost',
  DEV: 'development',
  ACC: 'acceptance',
  PROD: 'production',
};

export const getEnvironment = (): string => {
  const host = window.location.host;
  if (host.includes('localhost')) return Environment.LOCALHOST;
  if (host.includes('dev')) return Environment.DEV;
  if (host.includes('acc')) return Environment.ACC;
  return Environment.PROD;
};

export const isCheckinSubdomain = (): boolean => {
  return window.location.hostname.includes('checkin');
};

export const subdomainLoginType = (): LoginType => {
  return isCheckinSubdomain() ? 'checkin' : 'admin';
};
