import { defineStore } from 'pinia';
import { CompliApi } from '@/api/CompliApi';
import { Law } from '@/api/types/model/Law';
import { ProjectLaw } from '@/api/types/model/Project';
import { useAuthStore } from '../auth';
import { useLocalStorage } from '@/composables/storage/useLocalStorage';
import noCompliance from './noCompliance.json';

interface INoCompliance {
  dashboard: {
    companyCompliance: boolean;
    eventProjectCompliantStatus: boolean;
  },
  projects: {
    companyCompliance: boolean;
  },
  myCompany: {
    companyCompliance: boolean;
    questionnaireTable: boolean;
    overviewCompliantStatus: boolean;
  },
  employees: {
    detailsComplianceTable: boolean;
    overviewCompliantStatus: boolean;
    idCheck: boolean;
    projectsComplianceQuestionnaire: boolean;
  },
  companies: {
    overviewCompliantStatus: boolean;
  }
}

export const useComplianceRuleStore = defineStore('complianceRule', {
  state: () => ({
    companyProjects: new Map<number, ProjectLaw>(),
    activeProject: null as null | ProjectLaw,
    rules: new Map<number, Law>(),
    rule: {
      noCompliance: null as null | INoCompliance,
    },
  }),

  getters: {
    companyProjectsList (state): ProjectLaw[] {
      return [...state.companyProjects.values()];
    },
    rulesList (state) {
      return [...state.rules.values()];
    },
  },

  actions: {
    async getCompanyProjects () {
      const { user, selectedCompanyId} = useAuthStore();
      const ls = useLocalStorage();

      let projects: ProjectLaw[] = [];
      if (!this.companyProjects.size) {
        projects = await CompliApi.Company.GetActiveProjects(selectedCompanyId);
        projects.forEach((project) => {
          this.companyProjects.set(project.id, project);
        });
      }

      if (!projects.length) return;

      // Set active project from LS or first in list
      const userCompProj = ls.getUserCompanyProject();
      const isProjectStored = userCompProj && userCompProj[0] === user.id && userCompProj[1] === selectedCompanyId;
      if (isProjectStored) {
        this.setActiveProject(userCompProj[2]);
        return;
      }

      this.setActiveProject(projects[0].id);
    },
    async getRules () {
      const authStore = useAuthStore();
      if (!authStore.isAuthenticated) return;

      const rules = await CompliApi.Law.Paginate();
      rules.result.forEach((rule) => {
        this.rules.set(rule.id, rule);
      });
    },
    setActiveRule (ruleId: number) {
      // Reset rules
      this.rule.noCompliance = null;

      // Set active rules
      if (ruleId === 3 && this.rules.get(ruleId)) {
        this.rule.noCompliance = noCompliance;
      }
    },
    setActiveProject (projectId?: number) {
      if (!projectId) return;

      const project = this.companyProjects.get(projectId);
      if (!project) return;

      this.activeProject = project;

      const { user, selectedCompanyId} = useAuthStore();
      const ls = useLocalStorage();
      const [ ruleId ] = project.lawIds;
      if (ruleId) {
        this.setActiveRule(ruleId);
        ls.setUserCompanyProject(user.id, selectedCompanyId, projectId);
      }
    },
    clearCompanyProjects () {
      this.companyProjects.clear();
    },
  },
});
