import RippleEffect from '@/assets/style/modules/ripple.module.scss';

export default {
  install: (app) => {
    app.directive('ripple', {
      mounted (el, binding) {
        el.addEventListener('click', (event) => {
          const ripple = document.createElement('span');
          const diameter = Math.max(el.clientWidth, el.clientHeight);
          const radius = diameter / 2;
          ripple.style.width = ripple.style.height = `${diameter}px`;
          ripple.style.left = `${event.clientX - el.offsetLeft - radius}px`;
          ripple.style.top = `${event.clientY - el.offsetTop - radius}px`;
          if (typeof binding.value === 'object') {
            ripple.style.animationDuration = `${binding.value.duration || 600}ms`;
            if (binding.value.color) {
              ripple.style.backgroundColor = binding.value.color;
            }
          } else {
            ripple.style.animationDuration = `${binding.value || 600}ms`;
          }
          ripple.classList.add(RippleEffect.ripple);

          const oldRipple = el.getElementsByClassName(RippleEffect.ripple)[0];
          if (oldRipple) oldRipple.remove();
          el.appendChild(ripple);
        });
      },
    });

    app.directive('cy', {
      mounted (el, binding) {
        if (!binding.value) {
          return;
        }
        // TODO, remove from production
        el.setAttribute('cy-data', binding.value);
      },
    });
  },
};