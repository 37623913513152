import { Primitive } from '@/utils/common-types';

export type UrlQueryParam = Record<string, Primitive>;

export const createQueryString = (params?: UrlQueryParam): string | undefined => {
  if (!params) return undefined;
  const query: string[] = [];
  Object.keys(params).filter(key => !!params[key]).forEach((key: string) => {
    const queryVal = params[key];
    query.push(`${key}=${encodeURIComponent(queryVal.toString())}`);
  });
  return query.join('&');
};

export const PrivacyPolicyHref = 'https://compli.nl/privacy-policy/';
export const JointControllerHref = '/Regeling voor Joint Controller.pdf';