import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { VIESSearchModel } from '@/api/types/model/VIES';

export class VIESApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'VIES';
  }

  public Query (query: string) {
    return this.doReq<VIESSearchModel>({
      path: `${query}`,
      method: HttpVerb.GET,
    });
  };
}