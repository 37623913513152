export enum ContentType {
  JSON = 'application/json',
  OCTETSTREAM = 'application/octet-stream',
  FORMDATA = 'multipart/form-data',
};

export enum Header {
  accept = 'Accept',
  contentType = 'Content-Type',
  connection = 'Connection',
};

export enum HttpVerb {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
};

export enum StatusCode {
  OK = 200,
  CREATED = 201,
  BAD = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  UNPROCESSABLE = 422,
  TOO_MANY_REQUESTS = 429,
}