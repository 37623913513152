export interface LoadScriptOptions {
  async?: boolean;
  defer?: boolean;
  attrs?: Record<string, string>;
  addTo?: 'body' | 'head' | 'head-prepend';
}

export class LoadScript {
  private src: string;
  private options: LoadScriptOptions;
  private callbacks: { (): void }[];
  private initialized: boolean = false;
  private loading: boolean = false;

  constructor (src: string, options?: LoadScriptOptions) {
    this.src = src;
    this.options = options || {
      async: false,
      defer: false,
      attrs: {},
      addTo: 'body',
    };
    this.callbacks = [];
  }

  public reset () {
    this.initialized = false;
  }

  public load (callback: { (): void } = () => {}) {
    if (this.initialized) {
      return callback();
    }
    this.callbacks.push(callback);
    if (this.loading) return;
    this.loading = true;
    this.loadScript();
  }

  private loadScript () {
    const script = document.createElement('script');
    script.src = this.src;
    if (this.options.async) script.async = true;
    if (this.options.defer) script.defer = true;
    if (typeof this.options.attrs === 'object') {
      Object.keys(this.options.attrs).forEach((key) => {
        if (this.options.attrs?.[key]) script.setAttribute(key, this.options.attrs[key]);
      });
    }
    script.onload = () => this.execCallbacks();
    if (this.options.addTo === 'head') document.head.append(script);
    else if (this.options.addTo === 'head-prepend') document.head.prepend(script);
    else document.body.append(script);
  }

  private execCallbacks () {
    this.initialized = true;
    this.callbacks.forEach(callback => callback());
  }
}