import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faBuilding,
  faTachometerAlt,
  faInfoCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronLeft,
  faChevronRight,
  faExclamationCircle,
  faExclamation,
  faSearch,
  faBars,
  faCheck,
  faTimes,
  faInfo,
  faTimesCircle,
  faEdit,
  faPlus,
  faFilePdf,
  faDownload,
  faUndo,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faQrcode,
  faChevronDown,
  faChevronUp,
  faSave,
  faSync,
  faFile,
  faCogs,
  faEnvelope,
  faCheckCircle,
  faMinusCircle,
  faUpRightFromSquare,
  faMagnifyingGlassLocation,
  faWrench,
  faIndustry,
  faClock,
  faLanguage,
  faEyeSlash,
  faEye,
  faGaugeHigh,
  faUsers,
  faSitemap,
  faCity,
  faSquareCheck,
  faIdCard,
  faQuestionCircle,
  faKey,
  faArrowsRotate,
  faFileLines,
  faFileCsv,
  faClipboard,
  faBarsProgress,
  faPen,
  faBoxArchive

} from '@fortawesome/free-solid-svg-icons';

library.add(
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faBuilding,
  faTachometerAlt,
  faInfoCircle,
  faArrowCircleLeft,
  faArrowCircleRight,
  faChevronLeft,
  faChevronRight,
  faExclamation,
  faExclamationCircle,
  faSearch,
  faBars,
  faCheck,
  faTimes,
  faInfo,
  faEdit,
  faTimesCircle,
  faPlus,
  faFilePdf,
  faDownload,
  faUndo,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faQrcode,
  faChevronDown,
  faChevronUp,
  faSave,
  faSync,
  faFile,
  faMinus,
  faSignOutAlt,
  faCogs,
  faEnvelope,
  faCheckCircle,
  faMinusCircle,
  faUpRightFromSquare,
  faMagnifyingGlassLocation,
  faWrench,
  faIndustry,
  faClock,
  faLanguage,
  faEyeSlash,
  faEye,
  faGaugeHigh,
  faUsers,
  faSitemap,
  faCity,
  faSquareCheck,
  faIdCard,
  faQuestionCircle,
  faKey,
  faArrowsRotate,
  faFileLines,
  faFileCsv,
  faClipboard,
  faBarsProgress,
  faPen,
  faBoxArchive
);

export { FontAwesomeIcon };
