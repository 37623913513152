import { createApp, markRaw } from 'vue';
import Application from '@/Application.vue';
import router from '@/router';
import { FontAwesomeIcon } from '@/plugins/font-awesome';
import { createPinia } from 'pinia';
import translationPlugin from '@/plugins/translations';
import directivePlugin from '@/plugins/directives';
import deskproWidgetPlugin from '@/plugins/deskpro-widget';
import Toast, { PluginOptions, POSITION } from 'vue-toastification';
import { useAuthStore } from '@/stores/auth';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';
import 'vue-toastification/dist/index.css';
import 'floating-vue/dist/style.css';
import { getEnvironment } from '@/utils/environment';
import { useComplianceRuleStore } from './stores/complianceRules/compliance-rule';
import '@vuepic/vue-datepicker/dist/main.css';

const pinia = createPinia();
pinia.use(({ store }) => {
  // allow access to router from pinia stores
  store.$router = markRaw(router);
});

const toastOptions: PluginOptions = {
  position: POSITION.BOTTOM_CENTER,
  timeout: 3000,
};

const app = createApp(Application).use(pinia).use(deskproWidgetPlugin);

Sentry.init({
  app,
  dsn: 'https://9d0e59047a704019b4da61475bc60fa7@o4504519775682560.ingest.sentry.io/4504521054814208',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', 'compli.nl', /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
  logErrors: true,
  environment: getEnvironment(),
  enabled: process.env.NODE_ENV !== 'development',
});

const authStore = useAuthStore();
const complianceRuleStore = useComplianceRuleStore();
const initApp = async () => {
  try {
    await authStore.getUser();
    await complianceRuleStore.getRules();
  } catch {
    console.warn('User not authenticated!');
  }

  app
    .use(router)
    .use(translationPlugin)
    .use(directivePlugin)
    .use(Toast, toastOptions)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');
};

initApp();
