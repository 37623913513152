import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { Question, QuestionInputRequestBody } from '@/api/types/model/Question';
import { PaginationReq } from '@/api/types/model/PaginationReq';

export class QuestionApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Questions';
  }

  public Paginate (paginate?: PaginationReq) {
    return this.doPaginate<Question>({
      path: '',
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }
  public Get (questionId: number) {
    return this.doReq<Question>({
      path: `${questionId}`,
      method: HttpVerb.GET,
    });
  }
  public Create (question: QuestionInputRequestBody) {
    return this.doReq<QuestionInputRequestBody>({
      path: '',
      method: HttpVerb.POST,
      data: question,
    });
  }
  public Update (questionId: number, question: QuestionInputRequestBody) {
    return this.doReq<QuestionInputRequestBody>({
      path: `${questionId}`,
      method: HttpVerb.PUT,
      data: question,
    });
  }
}